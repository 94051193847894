<template>
  <layout selectedNav="data-analysis">
    <div class="pageContent" v-loading="showLoading">
      <div class="header">
        <div class="circleBox">
          <div
            class="circle"
            v-for="item in data"
            :key="item.index"
            :style="{ 'background-image': item.color }"
          >
            <div class="innerCircle">
              <div class="content">
                <span>
                  <span class="total">{{ item.count }}</span> 条</span
                >
                <span>{{ item.type }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="echartsWrapper">
        <div class="echartsTitle">数据资源统计</div>
        <div id="myChart"></div>
      </div>
      <div class="rankings">
        <div class="left" v-if="viewList">
          <div class="viewTitle">
            浏览量数据资源TOP10排名
          </div>
          <div class="dataList" v-for="(item, index) in viewList" :key="index">
            <div class="dataContent">
              <div class="leftData">
                <div
                  class="tag"
                  :style="{
                    background: 'rgba(69,181,189,' + item.opacity + ')'
                  }"
                >
                  <span class="text">TOP{{ item.num }}</span>
                </div>
                <span class="title">{{ item.title }}</span>
              </div>
              <div class="rightData">
                <i class="icon">
                  <img v-if="index < 3" src="../../assets/images/hot.png" />
                </i>
                <span class="times"
                  >{{ item.access }}<span style="font-size:10px">次</span></span
                >
              </div>
            </div>

            <div class="line" v-if="index < 9"></div>
          </div>
        </div>
        <div class="right">
          <div class="viewTitle">
            下载量数据资源TOP10排名
          </div>
          <div
            class="dataList"
            v-for="(item, index) in downloadList"
            :key="index"
          >
            <div class="dataContent">
              <div class="leftData">
                <div
                  class="tag"
                  :style="{
                    background: 'rgba(69,181,189,' + item.opacity + ')'
                  }"
                >
                  TOP{{ item.num }}
                </div>
                <span class="title">{{ item.title }}</span>
              </div>
              <div class="rightData">
                <i class="icon">
                  <img v-if="index < 3" src="../../assets/images/hot.png" />
                </i>
                <span class="times"
                  >{{ item.downsize
                  }}<span style="font-size:10px">次</span></span
                >
              </div>
            </div>

            <div class="line" v-if="index < 9"></div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/views/Layouts";
var echarts = require("echarts");
export default {
  components: {
    Layout
  },
  data() {
    return {
      data: [],
      total: "",
      viewList: "",
      downloadList: "",
      opacity: [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.29, 0.2, 0.09],
      dataProductList: [],
      dataInterfaceList: [],
      govementList: [],
      showLoading: false
    };
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      let option = {
        legend: {
          show: true,
          icon: "rect",
          itemWidth: 14,
          itemHeight: 14,
          itemGap: 50,
          data: ["数据产品", "数据接口"],
          textStyle: {
            fontSize: 14,
            color: "#656565",
            fontWeight: 400
          },
          x: "81%",
          y: "7%"
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "16%",
          bottom: "6%",
          containLabel: true
        },
        tooltip: {},
        xAxis: {
          type: "category",
          position: "bottom", // x轴的位置
          splitArea: {
            // 网格区域
            show: false // 是否显示，默认为false
          },
          // show: false,//不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          axisTick: {
            show: false //不显示坐标轴刻度线
          },
          axisLine: {
            show: false //不显示坐标轴线
          },
          axisLabel: {
            interval: 0,
            formatter: function(value) {
              //x轴的文字改为竖版显示
              var str = value.split("");
              return str.join("\n");
            },
            textStyle: {
              lineHeight: 14,
              color: "#656565", //更改坐标轴文字颜色
              fontSize: 12, //更改坐标轴文字大小
              fontWeight: 500
            }
          },
          // data: ["热门"]
          data: this.govementList
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#656565", //更改坐标轴文字颜色
              fontSize: 12, //更改坐标轴文字大小
              fontWeight: 400
            }
          },
          axisLine: {
            show: false //不显示坐标轴线
          },
          axisTick: {
            show: false
          },
          position: "left", // y轴位置;
          offset: 0 // y轴相对于默认位置的偏移
          // name: '数据资源统计',   // 轴名称
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            name: "数据产品",
            type: "bar",
            itemStyle: {
              // 图形的形状
              color: "#007DBF"
            },
            barWidth: 8, // 柱形的宽度
            barGap: "100%",
            barCategoryGap: "20%", // 柱形的间距
            data: this.dataProductList
          },
          {
            name: "数据接口",
            type: "bar",
            itemStyle: {
              // 图形的形状
              color: "#00BF7E"
            },
            barWidth: 8, // 柱形的宽度
            barGap: "100%",
            barCategoryGap: "20%", // 柱形的间距
            data: this.dataInterfaceList
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getViewList() {
      this.showLoading = true;
      this.$api.dataSource
        .list({
          paix: "FWCS",
          sx: "desc"
        })
        .then(res => {
          this.showLoading = false;
          if (res.code === 200) {
            this.total = res.data.totalRowNum;
            this.viewList = res.data.result.map((item, index) => {
              return {
                opacity: this.opacity[index],
                num: index + 1,
                title: item.title,
                access: item.access
              };
            });
            console.log(this.viewList);
          }
        })
        .catch(err => {
          this.showLoading = false;
          console.log(err);
        });
    },
    getDownloadList() {
      this.$api.dataSource
        .list({
          paix: "XZCS",
          sx: "desc"
        })
        .then(res => {
          if (res.code === 200) {
            this.downloadList = res.data.result.map((item, index) => {
              return {
                opacity: this.opacity[index],
                num: index + 1,
                title: item.title,
                downsize: item.downsize
              };
            });
            console.log(this.downloadList);
          }
        });
    },
    getDataCount() {
      this.$api.dataSource.menu().then(res => {
        if (res.code === 200) {
          this.data = [
            {
              type: "资源总量",
              color: "linear-gradient(#69ACFF, #5984F8)",
              count: this.total
            },
            {
              type: "资源领域",
              count: res.data.data.countsize,
              color: "linear-gradient( #FFD07E, #FFC142) "
            },
            {
              type: "应用场景",
              count: res.data.app.countsize,
              color: "linear-gradient( #6FE6FF, #0DBFAD) "
            },
            {
              type: "数据产品",
              count: res.data.type.value[1].size,
              color: "linear-gradient( #81FFA7, #009C0E) "
            },
            {
              type: "数据接口",
              count: res.data.type.value[0].size,
              color: "linear-gradient( #EEE3FE, #C694FD)"
            }
          ];
        }
      });
    },
    getEChartsData() {
      this.$api.dataSource.chart().then(res => {
        if (res.code === 200) {
          let govementList = [];
          let dataProductList = [];
          let dataInterfaceList = [];
          res.data[1].map(item => {
            govementList.push(item.szf);
            dataProductList.push(item.size);
          });
          res.data[0].map(item => {
            dataInterfaceList.push(item.size);
          });
          this.dataProductList = dataProductList;
          this.dataInterfaceList = dataInterfaceList;
          this.govementList = govementList;
          console.log(res.data);
          // console.log(this.dataProductList);
          // console.log(this.dataInterfaceList);
          // console.log(this.govementList);
          this.drawLine();
        }
      });
    }
  },
  mounted() {
    this.getDownloadList();
    this.getViewList();

    this.getDataCount();
    this.getEChartsData();
  }
};
</script>

<style lang="scss" scoped>
.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    .circleBox {
      margin-left: 35px;
      margin-right: 35px;
      width: 1130px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        background: pink;
        border-radius: 50%;
        .innerCircle {
          width: 109px;
          height: 109px;
          border-radius: 50%;
          background: #fff;
          .content {
            width: 100%;
            height: 100%;
            .total {
              font-size: 28px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 40px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }
      }
    }

    margin-top: 40px;
    margin-bottom: 20px;
    background-color: #fff;
    width: 1200px;
    height: 150px;
  }
  .echartsWrapper {
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: #fff;
    width: 1200px;
    height: 460px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    .echartsTitle {
      position: absolute;
      top: 20px;
      left: 19px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
    #myChart {
      width: 1200px;
      height: 460px;
    }
  }
  .rankings {
    background-color: #fff;
    width: 1200px;
    height: 574px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    .left {
      margin-top: 26px;
      margin-bottom: 20px;
      margin-left: 19px;
      width: 550px;
      // height: 514px;
      .viewTitle {
        display: inline-block;
        padding-bottom: 11px;
        width: 234px;
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }
      .dataList {
        margin-top: 14px;
        .dataContent {
          display: flex;
          justify-content: space-between;
          .leftData {
            display: inline-block;
            .tag {
              text-align: center;
              display: inline-block;
              margin-right: 10px;
              width: 35px;
              height: 20px;
              background: #45b5bd;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-size: 10px;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }
            .title {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
          .rightData {
            position: relative;
            .icon {
              position: absolute;
              right: 50px;
              top: -2px;
            }
            .times {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
        }
        .line {
          margin-top: 15px;
          width: 550px;
          height: 1px;
          background: #e5e5e5;
        }
      }
    }
    .right {
      .viewTitle {
        display: inline-block;
        padding-bottom: 11px;
        width: 234px;
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }
      .dataList {
        margin-top: 14px;
        .dataContent {
          display: flex;
          justify-content: space-between;
          .leftData {
            display: inline-block;
            .tag {
              display: inline-block;
              margin-right: 10px;
              width: 35px;
              height: 20px;
              background: #45b5bd;
              text-align: center;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-size: 10px;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }
            .title {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
          .rightData {
            position: relative;
            .icon {
              position: absolute;
              right: 50px;
              top: -2px;
            }
            .times {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
        }
        .line {
          margin-top: 15px;
          width: 550px;
          height: 1px;
          background: #e5e5e5;
        }
      }
      margin-top: 26px;
      margin-bottom: 20px;
      margin-right: 19px;
      width: 550px;
      // height: 514px;
    }
  }
}
</style>
